<template>
  <HorizontalBarsChartStyled :height="height">
    <canvas width="100%" height="100%" ref="canvas" />
  </HorizontalBarsChartStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import ChartMixin from '@/mixins/ChartMixin'
const HorizontalBarsChartStyled = styled('div')`
  width: 100%;
  height: ${p => (p.height > 0 ? p.height + 'vh' : '100%')};
  overflow-x: hidden;
  overflow-y: ${p => (p.height >= 1 ? 'auto' : 'hidden')};
}
`
export default {
  mixins: [ChartMixin],
  components: {
    HorizontalBarsChartStyled,
  },
  props: {
    height: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      chartType: 'horizontalBar',
    }
  },
}
</script>
